/* General Button Styles */
.menu-item button {
    background-color: #444;
    color: #fff;
    border: 1px solid #666; /* Subtle border for depth */
    border-radius: 15px; /* Rounded corners */
    padding: 6px 10px; /* Slightly larger padding for better visibility */
    font-size: 12px;
    cursor: pointer;
    margin: 0 5px; /* Adds spacing between buttons */
    font-family: Arial, sans-serif;
    transition: background-color 0.2s ease, transform 0.1s ease;
    white-space: nowrap; /* Prevents button text from wrapping */
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2); /* Adds depth with shadow */
}

.menu-item button:hover {
    background-color: #555;
    border-color: #888; /* Highlight border on hover */
    transform: scale(1.05); /* Subtle hover effect */
}

/* Button Container Styling */
.menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px; /* More padding for better spacing */
    border-bottom: 1px solid #444;
    gap: 5px;
}

.menu-item span {
    flex-grow: 1; /* Takes up remaining space */
    font-size: 14px;
    color: #fff;
    font-family: Arial, sans-serif;
    text-transform: capitalize;
}

/* Button Grouping for Cleaner Layout */
.menu-item .button-container {
    display: flex;
    flex-direction: row;
    gap: 5px; /* Space between buttons */
}


.drawer {
    position: absolute;
    top: 0;
    left: 30px; /* Push the drawer 30px from the left */
    width: 250px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
}

/* Hamburger Menu Styles */
.drawer-toggle {
    position: absolute;
    top: 10px; /* Adjust top placement as needed */
    left: 30px; /* Push it 30px from the left */
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    cursor: pointer;
    z-index: 1001;
}


.drawer-toggle:hover {
    background-color: #555; /* Slightly lighter on hover */
    transform: scale(1.1); /* Subtle hover effect */
}

.drawer-toggle:active {
    transform: scale(0.95); /* Slightly smaller on click */
}

/* Hamburger Icon Styling */
.drawer-toggle::before {

    font-size: 18px; /* Adjust size of the icon */
    color: #fff; /* White color for visibility */
}


/* Adjusted Styling for Drawer Content */
.drawer-content {
    display: flex;
    flex-direction: column;
    gap: 15px; /* More space between categories */
}

.drawer.open {
    transform: translateX(0);
    left: 30px; /* Push it 30px from the left when open */
}

/* Alternate Button Styling for Compact Buttons */
.menu-item .button-container button {
    padding: 5px 8px; /* Compact padding for smaller buttons */
    font-size: 12px;
    border-radius: 12px; /* Matches rounded design */
}

/* Active Category Styling */
.category-title {
    font-size: 14px;
    color: #eee;
    background-color: #333;
    border-radius: 12px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.category-title:hover {
    background-color: #555;
}

/* Active Button Styling */
.menu-item button.active {
    background-color: #666;
    border-color: #888;
    color: #fff;
    font-weight: bold;
}

.lightsaber-controls {
    margin-bottom: 20px;
}

.lightsaber-controls h3 {
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #333;
    padding: 10px;
    border-radius: 5px;
}

.lightsaber-controls-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.lightsaber-controls-buttons .toggle-button {
    padding: 10px;
    background-color: #444;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.lightsaber-controls-buttons .toggle-button.active {
    background-color: #666;
}

.lightsaber-controls-buttons .toggle-button:hover {
    background-color: #555;
}


.lightsaber-control {
    margin-top: 10px;
    padding: 10px;
    background-color: #333;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    color: white;
    text-align: center;
}

.lightsaber-control h4 {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
}

.saber-buttons button {
    margin: 5px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #444;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.saber-buttons button:hover {
    background-color: #555;
}

.saber-buttons button.active {
    background-color: #e63946; /* Glowing red to show it's active */
    box-shadow: 0px 0px 10px 2px rgba(230, 57, 70, 0.8); /* Glow effect */
}

.saber-buttons button.active.yellow {
    background-color: #f4d03f; /* Glowing yellow for active */
    box-shadow: 0px 0px 10px 2px rgba(244, 208, 63, 0.8); /* Glow effect */
}
