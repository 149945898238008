.dice-roller-container {
  width: 250px;
  margin: 0 auto;
  border-radius: 20px; /* Rounded corners */
  background: linear-gradient(350deg, rgba(250, 162, 38, 0.6), rgba(154, 109, 19, 0.6)); /* Gradient gold trim */
  padding: 5px; /* Padding for the golden border */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
  overflow: hidden; /* Ensures content respects the border radius */
}

.dice-roller {
  position: relative;
  text-align: center;
  background: linear-gradient(210deg, rgba(49, 8, 8, 0.8), rgba(49, 11, 11, 0.658)); /* Inner gradient background */
  border-radius: 15px; /* Matches the border’s rounded corners */
  padding: 20px;
  font-family: 'MedievalSharp', cursive;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.7); /* Adds an inner shadow for depth */
}

.dice-roller button {
  font-size: 1.5em;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: rgba(126, 90, 34, 0.9); /* Semi-transparent gold */
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
  border: none; /* Remove default border */

  /* Gradient border using box-shadow */
  box-shadow: 0 0 0 3px rgba(250, 162, 38, 0.6), /* Outer gradient */
              0 0 0 6px rgba(154, 109, 19, 0.6); /* Inner gradient */
}

.dice-roller button.shake {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

.dice-roller button:focus {
  outline: 2px solid #D4AF37; /* Highlighted outline on focus */
}

.dice-roller button:active {
  transform: translateY(2px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.dice-roller p {
  font-size: 1.8em;
  margin-top: 15px;
  color: #D4AF37;
  text-shadow: 2px 2px 5px #000;
  animation: fadeIn 1s ease-out;
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.battle-roller-container {
  margin-top: 20px;
  text-align: center;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.critical-success {
  animation: shake 0.5s, glowSuccess 1.5s ease-in-out infinite;
  background: gold;
  border: 5px solid orange;
  box-shadow: 0px 0px 20px gold;
}

.critical-fail {
  animation: shake 0.5s;
  background: rgb(181, 3, 3);
  border: 5px solid rgb(56, 6, 6);
  box-shadow: 0px 0px 20px rgb(255, 255, 255);
}

.fireworks {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: radial-gradient(circle, gold, transparent);
  opacity: 0;
  animation: explode 1s ease-out forwards;
  transform: translate(-50%, -50%);
}

@keyframes explode {
  0% { transform: scale(0); }
  100% { transform: scale(3); opacity: 0; }
}

@keyframes glowSuccess {
  0% { box-shadow: 0 0 10px gold; }
  50% { box-shadow: 0 0 30px gold; }
  100% { box-shadow: 0 0 10px gold; }
}
