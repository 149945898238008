/* src/App.css */

/* General Styles */
body, html, #root {
  background-color: #000000; /* Black background */
  color: #ffffff; /* White text */
  font-family: 'Roboto', sans-serif; /* Modern-ish font family */
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;  /* Prevent horizontal scroll */
  overflow-y: auto;    /* Allow vertical scroll */
}

.home-page, body, html {
  background-color: #000000; /* Black background */
  color: #ffffff; /* White text */
  font-family: 'Roboto', sans-serif; /* Modern-ish font family */
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;  /* Prevent horizontal scroll */
  overflow-y: auto;    /* Allow vertical scroll */
}

.dashboard-container, body, html {
  background-color: #000000; /* Black background */
  color: #ffffff; /* White text */
  font-family: 'Roboto', sans-serif; /* Modern-ish font family */
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;  /* Prevent horizontal scroll */
  overflow-y: auto;    /* Allow vertical scroll */
}

.doll-selection-page, body, html {
  background-color: #000000; /* Black background */
  color: #ffffff; /* White text */
  font-family: 'Roboto', sans-serif; /* Modern-ish font family */
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;  /* Prevent horizontal scroll */
  overflow-y: auto;    /* Allow vertical scroll */
}

a {
  color: #ffffff;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p, a, span, label, button {
  color: inherit;
}

button {
  background-color: #333333; /* Dark gray buttons */
  border: none;
  color: #ffffff;
  cursor: pointer;
  padding: 10px 20px;
}

button:hover {
  background-color: #555555; /* Lighter gray on hover */
}

/* App-Specific Styles */
.App {
  text-align: center;
}

.App-header {
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Drawer Styles */
.drawer {
  position: absolute;
  top: 60px; /* Ensure there is space between the toggle button and the drawer content */
  left: 10px;
  background-color: #333;
  color: white;
  padding: 20px;
  z-index: 1000;
  max-height: 80vh;
  overflow-y: auto;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.drawer.open {
  transform: translateX(0);
}

.drawer button {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #444;
  border: none;
  cursor: pointer;
  color: white;
}

.drawer button:hover {
  background-color: #555;
}

.drawer-content {
  padding-top: 60px; /* Adjust padding to create space at the top for better separation */
}

.drawer-toggle {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1100; /* Ensure it stays above other elements */
  background-color: #333;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

/* Outfit Controls */
.outfit-controls {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  position: absolute;
  right: -10px;
  top: -20px;
}

.outfit-controls button {
  background-color: #222;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 12px;
  margin: 2px 0;
  padding: 5px;
}

.outfit-controls button:hover {
  background-color: #444;
}

/* Outfit and Thumbnail Styling */
.outfit-item {
  cursor: pointer;
  position: absolute;
  z-index: 1;  /* Ensure outfits are above the main image */
}

.thumbnail {
  display: block;
  height: auto;  /* Use the image's natural height */
  margin: 0 auto; /* Center the thumbnail horizontally */
  max-height: 150px; /* Optionally, set a maximum height */
  max-width: 150px;  /* Optionally, set a maximum width */
  width: auto;   /* Use the image's natural width */
}

/* Canvas for Konva */
canvas {
  display: block;
  margin: 20px auto;
}

/* Pages Styling */
.home-page, .mode-selection-page, .story-selection-page {
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  position: relative; /* To position the back button */
}

.home-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  min-height: 100vh;
  padding-bottom: 100px; /* Space for the watermark */
}


/* Back Button */
.back-button {
  position: absolute;
  top: 20px;
  left: 20px; /* Positioned to the upper left */
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.back-button:hover {
  background-color: #555;
}

/* Patreon Support Section */
/* Ensure the Patreon support is at the bottom and does not float */
.patreon-support {
  text-align: center;
  margin-top: auto; /* Pushes it to the bottom of the page */
}

.doll-page-watermark {
  width: 150px; /* Adjust this size as needed */
  height: auto;
  opacity: 0.7;
  margin-bottom: 10px;
  position: absolute; /* Makes the element positioned relative to the container */
  right: 10px; /* 10px padding from the right */

}

.watermark {
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.patreon-text {
  margin: 10px 0;
}

/* Doll Selection Page */
.doll-selection-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh; /* Ensure the page takes at least the full height of the viewport */
  box-sizing: border-box;
  justify-content: flex-start; /* Start content from the top */
  overflow-y: auto; /* Enable vertical scrolling */
}

.doll-selection-page h1 {
  margin-bottom: 20px;
  text-align: center; /* Center align the "Select Your Doll" title */
}

/* Doll Grid Styling */
.doll-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px; /* Add space between the grid and footer content */
}

/* Doll Item Styling */
.doll-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 150px; /* Set a fixed width to ensure uniform alignment */
}

/* Thumbnail Styling with Rounded Corners */
.thumbnail {
  width: 100%;
  max-width: 150px; /* Maximum size for larger screens */
  height: auto;
  border-radius: 8px; /* Rounded corners only on images */
  margin-bottom: 5px; /* Slightly reduce spacing between image and text */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Light shadow for depth */
}

/* Upgrade Section */
.upgrade-section {
  margin: 20px 0;
  text-align: center;
}


.upgrade-button {
  background-color: #f96854;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
}

.patreon-text {
  margin: 10px 0;
}

/* Media Queries */
@media (max-width: 768px) {
  .doll-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller screens */
  }
}

@media (max-width: 400px) {
  .doll-grid {
    grid-template-columns: 1fr; /* Single column for very small screens */
  }
}

/* Doll Selection Page */
.doll-selection-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;  /* Ensures the page takes up full height */
  padding: 20px;
  box-sizing: border-box;
}



h1 {
  margin-bottom: 20px;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000;
}



/* Media Queries */
@media (max-width: 768px) {
  .drawer {
    max-width: 90%;
  }

  .drawer-toggle {
    top: 10px;
    left: 10px;
  }
}

@media (max-width: 600px) {
  .home-page {
    padding: 10px;
  }

  .drawer-toggle {
    z-index: 1100; /* Ensure it's above the drawer */
  }
}

/* ElvenHunter Full Body Image Sizing for Story Selection Page */
.elvenhunter-story-thumbnail {
  width: 30vw;  /* 30% of the viewport width */
  max-width: 300px;  /* Max width to ensure it doesn't get too large */
  height: auto;  /* Keep aspect ratio */
  display: block;
  margin: 0 auto; /* Center horizontally */
}

/* Canvas container with 16:9 aspect ratio */
.game-canvas-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (9 / 16 * 100) */
  overflow: hidden;
}

/* Game canvas to house all elements */
.game-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Background image to cover the entire canvas */
.game-canvas-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

/* Audio control icons */
.icon-white {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(200%) contrast(100%);
}

/* Container for audio controls */
.audio-controls-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 20; /* Ensure it stays on top */
}

/* Main label for MUSIC */
.audio-label-main {
  font-size: 14px;
  color: white;
  margin-bottom: 5px;
  text-align: left;
}

/* Audio control icons */
.audio-controls-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.audio-label {
  font-size: 1.2em;
  text-align: center;
  width: 100%;
}

.audio-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.audio-control {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Add spacing between each control */
}

.audio-controls .icon-white {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.audio-button-label {
  margin-left: 5px;
}

/* General button styles */
.button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


/* Logout Button (Black with orange border) */
.logout-button {
  background-color: black;
  color: #f96854;
  border: 2px solid #f96854;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.logout-button:hover {
  background-color: #f96854;
  color: #fff;
}

/* Dashboard Container */
.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;   /* Horizontally center the content */
  justify-content: flex-start;  /* Start from the top to allow scrolling */
  min-height: 100vh;      /* Minimum height of the viewport */
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;       /* Enable vertical scrolling if the content overflows */
}

/* Inner content wrapper to control width and alignment */
.dashboard-content {
  width: 100%;
  max-width: 400px;     /* Restricts the width to a maximum for larger screens */
  text-align: left;     /* Left-aligns the content */
}

/* Button group styles */
.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

/* Responsive Styles for Mobile (9:16 Portrait Mode) */
@media screen and (max-width: 768px) {
  .dashboard-content {
    max-width: 300px;   /* Adjusts the width for smaller devices */
  }
}

/* Add padding or margin to prevent content from sticking to the bottom */
body {
  margin-bottom: 50px;
}

.home-page .watermark-image {
  width: 80vw; /* Set the image to be 80% of the viewport width */
  max-width: 500px; /* Keep the max-width to 500px */
  margin-bottom: 20px;
}

.home-page {
  padding: 0 20px; /* Add padding to the container to ensure the image and content are padded */
  box-sizing: border-box; /* Ensure padding is included in the width calculation */
}

@media (min-width: 500px) {
  .home-page .watermark-image {
    width: 100%; /* On larger screens, allow it to scale without padding */
  }
}

/* Style the footer */
.footer {
  background-color: #000;
  text-align: center;
  padding: 20px;
  position: relative;
  width: 100%;
  bottom: 0;
}

.select-watermark {
  width: 200px;
}

.back-button {
  font-size: 1em;    /* Customize the size if needed */
  padding: 5px 10px;  /* Add some padding for better appearance */
  border-radius: 5px;
  margin-right: 20px;
}

.back-button-free {
  font-size: 1em;    /* Customize the size if needed */
  padding: 5px 10px;  /* Add some padding for better appearance */
  border-radius: 5px;
  margin-right: 20px;
}

.drawer-content .back-button {
background-color: #f96854;
}

.headband-button {
  font-size: 1em;
  margin-top: 50px;
  padding: 10px 50px;
}
.drawer-content .back-button-free {
  background-color: #00bfff;
  }