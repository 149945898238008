.home-page {
    text-align: center;
    font-family: Arial, sans-serif;
    color: #fff;
    background-color: #000;
    padding: 20px;
  }
  
  .watermark-image {
    width: 150px;
    margin: 0 auto;
    display: block;
  }
  
  h1 {
    margin-top: 20px;
    font-size: 2rem;
  }
  
  p {
    margin-bottom: 20px;
    font-size: 1.2rem;
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
  }
  
  .primary-button,
  .secondary-button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .primary-button {
    background-color: #00bfff;
    color: #fff;
  }
  
  .secondary-button {
    background-color: #ccc;
    color: #000;
  }
  
  .primary-button:hover,
  .secondary-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  .footer-links {
    margin-top: 40px;
    font-size: 0.9rem;
  }
  
  .footer-links a {
    color: #fff;
    text-decoration: none;
    margin: 0 5px;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
/* Patreon link styling */
.patreon-link {
    color: white;
    font-weight: bold;
    background-color: #ff424d; /* Patreon orange */
    padding: 8px 16px;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  .patreon-link:hover {
    background-color: #d0323b; /* Darker orange for hover */
    transform: scale(1.1);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
  }
  
  .patreon-link:active {
    transform: scale(1);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  }
  